export default {
  cl: {
    required: [3],
    display: [3]
  },
  co: {
    required: [2],
    display: [1, 2]
  },
  pe: {
    required: [3],
    display: [2, 3]
  },
  ar: {
    required: [2],
    display: [1, 2]
  },
  mx: {
    required: [2],
    display: [1, 2]
  },
  ec: {
    required: [2],
    display: [1, 2]
  }
}